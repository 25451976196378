import { createContext, Dispatch } from 'react';

type FileUploadState = { status: FileUploadStatus; file: File | null };

type FileUploadEvent =
  | { type: 'FILE_SELECTED'; payload: File }
  | { type: 'SET_STATUS'; payload: FileUploadStatus }
  | { type: 'DELETE_FILE' };

type FileUploadStatus = 'IDLE' | 'UPLOADING' | 'COMPLETE';

export const getInitialReducerState = (initialFile?: File): FileUploadState => {
  return {
    file: initialFile || null,
    status: initialFile ? 'COMPLETE' : 'IDLE',
  };
};

export const reducer = (state: FileUploadState, action: FileUploadEvent): FileUploadState => {
  switch (action.type) {
    case 'DELETE_FILE':
      return {
        ...state,
        file: null,
        status: 'IDLE',
      };
    case 'FILE_SELECTED':
      return {
        ...state,
        file: action.payload,
        status: 'UPLOADING',
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    default:
      return { ...state };
  }
};

export const FileUploadContext = createContext<{
  state: FileUploadState;
  dispatch: Dispatch<FileUploadEvent>;
}>({ state: getInitialReducerState(), dispatch: () => {} });
