export const getFileSizeMb = (fileSize: number) => {
  const fileSizeMb = fileSize / (1024 * 1024);
  return fileSizeMb < 1 ? '<1' : fileSizeMb.toFixed(1);
};

export function triggerDownload(url: string) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

// Browsers prevent multiple downloads for happening within the same second, so we set a timeout after downloading
// each file before attempting the next
const MULTIPLE_DOWNLOAD_TIMEOUT = 1000;

const multipleDownloadTimeout = () => {
  return new Promise((resolve) => setTimeout(resolve, MULTIPLE_DOWNLOAD_TIMEOUT));
};

export const triggerMultipleDownloads = async (urls: string[]) => {
  let processPointer = 0;

  while (processPointer < urls.length) {
    triggerDownload(urls[processPointer]);

    await multipleDownloadTimeout();
    processPointer = processPointer + 1;
  }
};
