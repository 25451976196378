import papaparse from 'papaparse';

import { triggerDownload } from '@attentive/file-utils';

type CsvRow = Record<string, string | number | boolean>;
type CsvRows = CsvRow[];

/**
 *
 * @param rows - an array of objects representing the rows in a CSV. The object keys are used
 * as headers and should be consistent across all of the objects in the array.
 */
export function downloadDataAsCsv(rows: CsvRows) {
  const fileUrl = getCsvUrl(rows);
  triggerDownload(fileUrl);
}

function getCsvUrl(rows: CsvRows) {
  const csv = papaparse.unparse(rows, { delimiter: ',', header: true });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  return window.URL.createObjectURL(blob);
}
