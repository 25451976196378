import React, { ComponentProps, useContext, useReducer } from 'react';

import { PicnicCss, Text, Stack, Icon, IconButton, FileInput } from '@attentive/picnic';

import { getFileSizeMb } from '../../utils';

import { FileUploadContext, getInitialReducerState, reducer } from './reducer';

export const useFileUploadContext = () => {
  return useContext(FileUploadContext);
};

export const Container: React.FC<{
  children: React.ReactNode;
  css?: PicnicCss;
}> = ({ children, css }) => {
  return (
    <Stack
      css={{
        width: '100%',
        padding: '$space4',
        border: '1px solid $borderDefault',
        borderRadius: '$radius2',
        ...css,
      }}
    >
      {children}
    </Stack>
  );
};

type UploadedFileProps = {
  css?: PicnicCss;
  disabled?: boolean;
  onDelete?: () => void;
};

export const UploadedFile: React.FC<UploadedFileProps> = ({ css, disabled, onDelete }) => {
  const { dispatch, state } = useFileUploadContext();

  const handleDelete = () => {
    dispatch({ type: 'DELETE_FILE' });
    onDelete?.();
  };

  if (!state.file) return null;

  return (
    <Stack
      direction="horizontal"
      css={{
        width: '100%',
        padding: '$space4',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid $borderDefault',
        borderRadius: '$radius2',
        fontWeight: '$bold',
        ...css,
      }}
    >
      <Stack direction="horizontal" spacing="$space2" css={{ alignItems: 'center' }}>
        <Icon name="TextFileFilled" size="medium" />
        <Text>{state.file.name}</Text>
      </Stack>
      <Stack direction="horizontal" spacing="$space2" css={{ alignItems: 'center' }}>
        <Text color="subdued">{getFileSizeMb(state.file.size)}MB</Text>
        <IconButton
          iconName="Delete"
          description={`Delete ${state.file.name}`}
          onClick={handleDelete}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

type FileUploadButtonProps = Exclude<ComponentProps<typeof FileInput>, 'onChange'> & {
  children: React.ReactNode;
  onUpload: (file: File) => Promise<void> | void;
  onError?: (err: string) => void;
};

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  children,
  disabled,
  onUpload,
  onError,
  ...props
}) => {
  const { dispatch, state } = useFileUploadContext();

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];

    if (!file) {
      throw new Error('File is missing');
    }

    dispatch({ type: 'FILE_SELECTED', payload: file });

    try {
      await onUpload(file);
      dispatch({ type: 'SET_STATUS', payload: 'COMPLETE' });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      onError?.(errorMessage);
      dispatch({ type: 'DELETE_FILE' });
    }
  };

  return (
    <FileInput
      {...props}
      onChange={onFileChange}
      disabled={disabled || state.status === 'UPLOADING'}
    >
      {children}
    </FileInput>
  );
};

const FileUploadComponent: React.FC<{ children: React.ReactNode; initialFile?: File }> = ({
  children,
  initialFile,
}) => {
  const [state, dispatch] = useReducer(reducer, getInitialReducerState(initialFile));

  return (
    <FileUploadContext.Provider value={{ state, dispatch }}>{children}</FileUploadContext.Provider>
  );
};

type ComponentType = typeof FileUploadComponent;
interface CompositeComponent extends ComponentType {
  Button: typeof FileUploadButton;
  Container: typeof Container;
  UploadedFile: typeof UploadedFile;
}

const FileUpload = FileUploadComponent as CompositeComponent;
FileUpload.Button = FileUploadButton;
FileUpload.Container = Container;
FileUpload.UploadedFile = UploadedFile;

FileUpload.displayName = 'FileUpload';
FileUpload.Button.displayName = 'FileUpload.Button';
FileUpload.Container.displayName = 'FileUpload.Container';
FileUpload.UploadedFile.displayName = 'FileUpload.UploadedFile';

export { FileUpload };
